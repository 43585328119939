<script setup lang="ts">
  import Compressor from 'compressorjs'
  import type { AsyncComponentLoader } from 'vue'
  import type { Challenge } from '~/types/challenge'

  const { fileprovider } = useRuntimeConfig().public
  const { t, te } = useI18n()
  const { sendChallengeResponse, uploadMediaCloudinary, uploadMediaGcs } = useStoreChallenge()
  const { mutateAsync: sendResponse, error: responseError } = sendChallengeResponse()

  const props = withDefaults(
    defineProps<{
      challenge: Challenge
      show: boolean
      openFromAnchor: boolean
    }>(),
    {
      openFromAnchor: false
    }
  )

  const emit = defineEmits<{
    close: []
  }>()

  const actionStatus = ref(
    props.challenge.actions.reduce(
      (acc, item, index) => {
        acc[index] = false
        return acc
      },
      {} as { [key: string]: boolean }
    )
  )

  const response = ref()
  const isLoading = ref(false)
  const msgError = ref('')

  const branding = useBrandingButton().extractButton('branding_challenge', 'action_btn')

  const missionsWithoutSubmit = ['judgeme', 'yotpo_review', 'type_form', 'webhook']
  const displayTypesInFooter = ['link', 'link_appstore', 'link_playstore', 'facebook_button', 'social_button']
  const displayTypesInPreview = ['media']

  // Ex : 'link_appstore' => 'LinkAppstore'
  const displayTypeToComponentName = (str: string): string => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')
  }

  // Put display_type components in 'components/section/challenges/display' with format link_appstore' => 'LinkAppstore'
  const displays = import.meta.glob('~/components/section/challenges/display/*.vue')
  const actions = import.meta.glob('~/components/section/challenges/action/*.vue')

  // cf. https://github.com/nuxt/nuxt/issues/14036
  const getDisplayComponent = (key: string) => {
    const val = displays[`/components/section/challenges/display/${displayTypeToComponentName(key)}.vue`]
    return val ? defineAsyncComponent(val as AsyncComponentLoader<Component>) : ''
  }
  const getActionComponent = (key: string) => {
    const val = actions[`/components/section/challenges/action/${displayTypeToComponentName(key)}.vue`]
    return val ? defineAsyncComponent(val as AsyncComponentLoader<Component>) : ''
  }

  onMounted(() => {
    useTrack().track('modal-missions-details', {
      mission_id: props.challenge.id,
      mission_name: props.challenge.name,
      mission_status: props.challenge.status as string,
      template_id: props.challenge.challenge_template_id,
      type_challenge: props.challenge.type_challenge,
      social_network: props.challenge.social_network
    })
  })

  const onActionComplete = (value: string, key: number) => {
    actionStatus.value[key] = true
    response.value = value
    useModalResize().update()
  }

  const onSubmit = async () => {
    useTrack().track('click-mission-details-validate', {
      mission_id: props.challenge.id,
      mission_name: props.challenge.name,
      template_id: props.challenge.challenge_template_id,
      type_challenge: props.challenge.type_challenge,
      social_network: props.challenge.social_network
    })
    const formData = new FormData()
    formData.append('id_challenge', String(props.challenge.id))

    try {
      isLoading.value = true
      // if challenge action is media, upload media before submitting
      if (['picture_url', 'video_url'].includes(props.challenge.key)) {
        let mediaUrl
        // if challenge action is picture, compress image before sending
        const mediaData = props.challenge.key === 'picture_url' ? await compressImage(response.value) : response.value
        if (fileprovider !== 'gcs') {
          // local dev, upload to cloudinary
          mediaUrl = await uploadMediaCloudinary(mediaData as string)
        } else {
          // production, upload to gcs
          mediaUrl = await uploadMediaGcs(mediaData as string)
        }
        if (props.challenge.key === 'picture_url') {
          formData.append('picture', mediaUrl)
        } else if (props.challenge.key === 'video_url') {
          formData.append('video', mediaUrl)
        }
      } else {
        formData.append(props.challenge.key, response.value)
      }

      await sendResponse({ formData })
      isLoading.value = false
      emit('close')
    } catch (e) {
      if (!responseError.value) {
        msgError.value = t('ApiErrors.generic')
      } else {
        const [responseErrorMsg] = Object.values(responseError.value['response']._data as { string: string })
        const transKey = `ApiErrors.${responseErrorMsg}`
        msgError.value = te(transKey) ? t(transKey) : t('ApiErrors.generic')
      }
    } finally {
      isLoading.value = false
    }
  }

  const compressImage = async (data: File | Blob) => {
    return await new Promise((resolve) => {
      new Compressor(data, {
        quality: 0.7,
        success: resolve
      })
    })
  }

  watch(
    () => props.challenge,
    (challenge) => {
      if (props.show && challenge.social_network === 'instagram' && isInstagramUserAgent()) {
        const route = getUrlPrefix(useRoute().params)
        useRouter().push(`${route}/in-app`)
      }
    },
    { immediate: true }
  )

  const shouldDisplayLegalText = computed(() => {
    const { social_network, type_challenge, ask_content_rights } = props.challenge
    return (
      ask_content_rights &&
      (social_network === 'media' ||
        (social_network === 'tiktok' && type_challenge === 'content_post') ||
        (social_network === 'instagram' && ['content_story', 'content_post', 'content_reel'].includes(type_challenge)))
    )
  })

  const { modalsOpenedCount, isModalFullPage, isMobile } = storeToRefs(useStoreApp())
  const footerRef = ref()

  const isPending = computed(() => props.challenge.status === 'PEN')

  const exampleDisplay = computed(() => {
    return props.challenge.display?.find((display) => display.display_type === 'media')
  })

  const contentDisplayItems = computed(() => {
    return props.challenge.display.filter(
      (d) => !displayTypesInFooter.includes(d.display_type) && !displayTypesInPreview.includes(d.display_type)
    )
  })

  const shouldTeleportBtnsToFooter = computed(() => {
    return footerRef.value && isMobile.value
  })
</script>

<template>
  <ModalApp
    class="challenge-modal"
    :class="{ 'child-modal': !openFromAnchor && modalsOpenedCount > 0 && !isModalFullPage && !isMobile }"
    footer-fixed
    :show
    :no-footer="!isMobile"
    @close="emit('close')"
  >
    <div
      class="challenge-modal__body"
      :class="{ 'with-preview': exampleDisplay }"
    >
      <SectionChallengesDisplayMedia
        v-if="exampleDisplay"
        :display="exampleDisplay"
      />
      <div class="challenge-modal__body--main">
        <div class="challenge-modal__title">
          <NuxtImg
            v-if="!challenge.background_desktop_url"
            :src="challenge.icon_url"
            width="40"
            height="40"
            loading="lazy"
          />
          <span class="text-primary-heading-1">{{ challenge.name }}</span>
        </div>
        <div class="challenge-modal__content">
          <Points :points="challenge.points_value" />
          <SectionChallengesMissionStatusPending v-if="isPending" />
          <SectionChallengesMissionStatusInvalid
            v-if="challenge.status === 'INV' && challenge.refusal_reason.length > 0"
            :refusal-reasons="challenge.refusal_reason"
          />
          <template
            v-for="(displayItem, key) in contentDisplayItems"
            :key="key"
          >
            <component
              :is="getDisplayComponent(displayItem.display_type)"
              :display="displayItem"
            />
          </template>
          <template
            v-for="(actionItem, key) in challenge.actions"
            :key="key"
          >
            <component
              :is="getActionComponent(actionItem.display_type)"
              :action="actionItem"
              :disabled="isPending"
              @complete="onActionComplete($event, key)"
              @incomplete="actionStatus[key] = false"
            />
          </template>
          <div
            v-if="shouldDisplayLegalText"
            class="challenge-modal__content--rights"
          >
            {{ t('SectionChallenges.ModalMissionDetail.imageRights') }}
          </div>
        </div>
        <div class="challenge-modal__btn">
          <ClientOnly>
            <Teleport
              :to="footerRef"
              :disabled="!shouldTeleportBtnsToFooter"
            >
              <template
                v-for="(displayItem, key) in challenge.display.filter((d) =>
                  displayTypesInFooter.includes(d.display_type)
                )"
                :key="key"
              >
                <component
                  :is="getDisplayComponent(displayItem.display_type)"
                  :display="displayItem"
                  :challenge="challenge"
                />
              </template>
              <ButtonBranding
                v-if="!missionsWithoutSubmit.includes(challenge.social_network)"
                class="text-primary-heading-2"
                :button-branding="branding"
                :disabled="Object.values(actionStatus).some((s) => !s) || isPending"
                :loading="isLoading"
                @click="onSubmit"
              >
                {{ $t('SectionChallenges.ModalMissionDetail.submit') }}
              </ButtonBranding>
              <FormError v-if="msgError">{{ msgError }}</FormError>
            </Teleport>
          </ClientOnly>
        </div>
      </div>
    </div>
    <template #footer>
      <div
        ref="footerRef"
        class="challenge-modal__footer"
      />
    </template>
  </ModalApp>
</template>
<style scoped>
  .challenge-modal {
    &__body {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &.with-preview {
        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 40px;
        }

        .challenge-modal__btn {
          @media (min-width: 768px) and (max-width: 1024px) {
            flex-direction: column;
          }

          & > button {
            max-width: 100%;
          }
        }
      }

      &--main {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    :deep(.lds-modal__content) {
      overflow-y: visible;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &__loader {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }

    &__btn {
      margin-top: 20px;
      gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      > button {
        max-width: 50%;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }

    &__content {
      &--rights {
        margin-top: 10px;
        color: var(--gray-400);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .child-modal {
    :deep(.lds-modal__container) {
      left: unset;
      top: unset;
      transform: unset;
    }
  }
</style>
